<template>
  <div class="parent">
    <div id="biao">

      <div id="card_body">
        <div id="table_head">
          <tr v-for="(i,index) in tbtitle" :key="index">
            <th>{{i}}</th>
          </tr>
        </div>
        <vue-seamless-scroll :data="sctable"
            :class-option="optionHover"
            class="seamless-warp"
            v-loading="loading">
          <div id="table_body">
            <table v-if="sctable.length!=0">
              <tr
              v-for="(k,index1) in sctable" :key="index1">
                <!-- <el-tooltip :content="k.productName"
                placement="left" effect="light"> -->
                <td style="max-width:250px;" v-text="k.productName"></td>
                <!-- </el-tooltip> -->
                <td style="color:#AC703B;" v-text="k.price"></td>
                <td v-text="k.amount"></td>
                <td v-text="k.siteName"></td>
                <td v-text="k.ts"></td>
              </tr>
            </table>
            <p class="nodata" v-else>{{$t('m.index.No_transaction_dynamics')}}</p>
          </div>
        </vue-seamless-scroll>
      </div>
      <div class="pay">
        <!-- <el-select v-model="selectValue" class="m-2" placeholder="请选择" size="large">
          <el-option
            v-for="item in nav"
            :key="item.id"
            :label="item.name"
            :value="item.name"
          >
          </el-option>
        </el-select> -->
        <!-- 快捷买入 -->
        <!-- <button class="btn" @click="this.$router.push('/map')">{{$t('m.index.Quick_buy')}}</button> -->
        <!-- <button class="btn pas" @click="this.$router.push('/map')">快捷卖出</button> -->
      </div>
    </div>
    <div id="navlist">
      <el-scrollbar max-height="360px">
        <ul>
          <li class="navshow" v-for="(item, index) in nav" :key="index"
          :class="{active:isact==index}"
          @click="addclass(index),selectList(item)">
            <img :src="item.icon" alt="">
            <span id="icon"></span>
            <i class="zi">{{item.name}}</i>
          </li>
        </ul>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue-demi'
import http from '../api/http'
import emitter from '../tools/bus'
// import { ref, nextTick } from 'vue'
// const tbprice = [6850, 6850, 6850, 6850, 6850, 6850, 6850, 6850]

// 品种(专题)数据
const topicList = (data) => {
  return http.get('/mall-portal/home/topicList')
}

// 首页获取成交明细
const getSuccessOrderList = (data) => {
  return http.get('/mall-portal/home/getSuccessOrderList', data)
}
// 根据品种id查询交易动态
const getSuccessOrderListById = (data) => {
  return http.get('/mall-portal/home/getSuccessOrderListById', data)
}
export default {
  name: 'RegLog',
  data () {
    return {
      // status: false,
      tbtitle: [
        this.$t('m.index.product_name'),
        this.$t('m.index.transaction_price'),
        this.$t('m.index.turnover'),
        this.$t('m.index.region'),
        this.$t('m.index.Transaction_time')],
      isact: 0,
      tablebg: true,
      a: 0,
      nav: [],
      loading: ref(true),
      sctable: [],
      selectValue: ref('')
    }
  },
  components: {
  },
  mounted () {
    this.initdata()
  },
  computed: {
    optionHover () {
      return {
        step: 0.6, // 数值越大速度滚动越快
        limitMoveNum: 7, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
      }
    }
  },
  methods: {
    async initdata () { // 初始化品种信息
      const data = {
        rankingClassification: 3
      }
      const getList = await getSuccessOrderList(data)
      this.sctable = getList.data.data
      this.loading = false
      // console.log(this.sctable)

      const res = await topicList()
      // console.log(res)
      this.nav = res.data.data
    },
    // tr栏背景颜色
    tabRowClassName ({ row, rowIndex }) {
      const index = rowIndex + 1
      if (index % 2 === 0) {
        return 'background-color: #f2f2f2;'
      }
    },
    // 咖啡图标切换选项
    addclass (k) {
      this.isact = k
    },
    // 点击查找品种交易动态
    async selectList (item) {
      this.loading = true
      console.log(item)
      emitter.emit('KLineListId', item.id)
      const data = {
        id: item.id
      }
      const res = await getSuccessOrderListById(data)
      console.log(res)
      if (res.data.code === 200) {
        this.sctable = res.data.data
        this.loading = false
      }
    }
  },
  setup () {
    return {
      // setRef
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../style/viriables.scss';
input::-webkit-input-placeholder{
  color: #333333;
}
.parent{
  display: flex;
  #biao{
    width: 630px;
    height: 350px;
    padding: 20px 0 20px 20px;
    #card_body{
      // height: 320px;
      // // overflow: hidden;
      font-size: 14px;
      font-family: Microsoft YaHei;
      #table_head{
        padding: 0 10px;
        display: flex;
        // padding-top: 20px;
        tr{
          width: 100%;
          display: flex;
          height: 40px;
          justify-content: center;
          line-height: 40px;
          // text-align: center;
          th{
            flex: 1;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #FFFFFF;
            // background: #AC703B;
            background: #50362E;
          }
        }
      }
      .seamless-warp{
        height: 300px;
        overflow: hidden;
        // margin: 10px 0;
        #table_body{
          display: flex;
          align-items: center;
          // justify-content: center;
          table{
            flex: 1;
            display: flex;
            flex-flow: column;
            font-size: 12px;
            padding: 0 10px;
            tr{
              height: 40px;
              flex-shrink: 0;
              border-bottom: 1px solid #cccccc;
              display: flex;
              td{
                text-align: center;
                flex: 1;
                line-height:40px;
                white-space:nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
              }
            }
            tr:hover{
              // cursor: pointer;
              background: rgba(255, 255, 255, 0.05);
            }
          }
          .nodata{
            flex: 1;
            font-size: 20px;
            height: 300px;
            line-height: 300px;
            font-weight: bold;
            color: #C6824E;
            // justify-content: center;
            text-align: center;
          }
        }
      }
    }

    .pay{
      margin-top: 15px;
      height: 40px;
      display: flex;
      float: right;
      .el-select{
        margin-right: 20px;
      }
      .btn{
        width: 120px;
        height: 40px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #FFFEFE;
        background: linear-gradient(0deg, #280E01 0%, #673000 100%);
        border-radius: 4px;
        border: 0;
      }
      .pas{
        margin-left: 10px;
        background: linear-gradient(0deg, #AC703B 0%, #FFBC88 100%);
      }
    }
  }
  #navlist{
    padding: 10px 0;
    ul{
      display: flex;
      flex-direction:column;
      align-content: center;
      margin-top: 10px;
      .navshow{
        width: 110px;
        height: 70px;
        // padding: 10px;
        position: relative;
        margin: 10px;
        display: flex;
        box-sizing: border-box;
        flex-direction:column;
        transition: all 0.2s;
        cursor: pointer;
        img{
          position: absolute;
          width: 110px;
          border-radius: 10px;
          height: 70px;
        }
        #icon{
          z-index: 2;
          margin: 0 auto;
          width: 33px;
          height: 29px;
          // border: 1px solid black;
          margin-bottom: 10px;
        }
        .zi{
          z-index: 2;
          color: #FFFFFF;
          font-size: 14px;
          font-weight: 600;
          text-align: center;
        }
      }
      .active{
          background: $bgColor;
      }
    }
  }
}
</style>
