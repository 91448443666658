<template>
  <div>
    <div class="nav_title">
      <span class="zi1" @click="this.$router.push(msg.to)">{{msg.title}}</span>
      <i class="zi2" v-if="msg.to" @click="this.$router.push(msg.to)">{{this.$t('m.index.more')}}&gt;</i>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Title',
  props: {
    msg: Object
  },
  setup (props, ctx) {
    // console.log(props.msg)
    return {
      // props
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../style/viriables.scss';

div{
  border-bottom: 1px solid $bgColor;
  .nav_title{
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    height: 70px;
    .zi1{
      float: left;
      font-size: 20px;
      // font-family: Microsoft YaHei;
      font-weight: bold;
      // color: #333333;
      color: #3C1C0B;
      line-height: 70px;
      cursor: pointer;
    }
    .zi2{
      text-align: right;
      line-height: 70px;
      float: right;
      cursor: pointer;
      color: #c3c3c3;
    }
  }
}
</style>
