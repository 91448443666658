<template>
  <div class="zixun">
    <div class="nav_title">
      <span class="zi1">{{$t('m.index.information_center')}}</span>
      <i class="zi2" @click="this.$router.push('/coffee/information')">{{$t('m.index.more')}} &gt;</i>
    </div>

    <div class="news" v-loading="loading">
      <ul>
        <li class="news_show" v-for="(item,index) in newsList" :key="index"
        @click="tozixun(item)">
          {{item.title}}
        </li>
        <p class="cont_p" v-if="newsList.length == 0">... {{$t('m.index.No_information_available')}} ...</p>
      </ul>
    </div>
  </div>
</template>
<script>
import { ref } from 'vue-demi'
import http from '../../../api/http'
// 查询咨询接口
const getTodayHeadline = (data) => {
  return http.get('/mall-portal/mall/post/queryPcPostByPage', data)
}
export default {
  name: 'Zixun',
  data () {
    return {
      loading: ref(true),
      newsList: []
    }
  },
  mounted () {
    this.initdata()
  },
  methods: {
    async initdata () { // 初始化首页咨询数据
      const Headline = await getTodayHeadline({
        pageNum: 1,
        pageSize: 6,
        postOrInformation: 1
      })
      // console.log(Headline)
      if (Headline.data.code === 200) {
        this.newsList = Headline.data.data.list
        this.loading = false
      }
    },
    tozixun (item) {
      console.log(item)
      this.$router.push({
        path: '/coffee/informationArticle',
        query: {
          id: item.id
        }
      })
    }
  },
  setup () {
    return {

    }
  }
}
</script>
<style lang="scss" scoped>
.zixun{
  padding: 20px 10px 0;
  box-sizing: border-box;
  background: #ffffff;
  .nav_title{
    display: flex;
    justify-content: space-between;
    .zi1{
      float: left;
      font-size: 20px;
      // font-family: Microsoft YaHei;
      font-weight: bold;
      // color: #333333;
      color: #3C1C0B;
      line-height: 39px;
    }
    .zi2{
      text-align: right;
      line-height: 39px;
      float: right;
      color: #c3c3c3;
      cursor: pointer;
    }
  }
  .news{
    min-height: 240px;
    ul{
      padding: 15px 0;
      li{
        font-size: 16px;
        // font-family: Microsoft YaHei;
        border-bottom: 1px solid #BEBEBE;
        font-weight: 400;
        color: #333333;
        line-height: 35px;
        text-overflow:ellipsis;
        white-space:nowrap;
        overflow:hidden;
        cursor: pointer;
        // display: flex;
        // align-items: center;
      }
      li:before{
        content: "\2022";
        color: #5A4944;
        font-size: 25px;
        // line-height: 30px;
        margin-right: 5px;
      }
      .cont_p{
        line-height: 210px;
        width: 100%;
        text-align: center;
        color: #c88756;
        font-size: 30px;
        // margin: 30px 0;
      }
    }
  }
}
</style>
