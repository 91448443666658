<template>
<ul class="wuliulist_ul">
  <li class="wuliulist_li" v-for="item of shengJiList" :key="item">
    <div class="left">
      <span class="citys">{{item.start}}</span>
      <el-icon><Right /></el-icon>
      <span class="citys">{{item.end}}</span>
    </div>
    <div class="right">
      <p>{{item.currencySymbol}}<span class="wuliuprice">{{item.price}}</span> / {{item.unit}}起</p>
    </div>
  </li>
</ul>
</template>

<script>
export default {
  name: 'WuliuList',
  props: {
    value: Object
  },
  data () {
    return {
      shengJiList: []
    }
  },
  methods: {

  },
  mounted () {
    // console.log(this.$props.value)
    this.shengJiList = this.$props.value
  },
  setup (props, ctx) {
    return {

    }
  }
}
</script>
<style lang='scss' scoped>
//@import ''; 引入公共css类
.wuliulist_ul{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  .wuliulist_li{
    box-sizing: border-box;
    // width: 33.33%;
    min-width: 33.33%;
    padding: 0 15px;
    margin-bottom: 15px;
    height: 36px;
    line-height: 36px;
    display: flex;
    font-weight: bold;
    .left{
      flex: 1;
      padding: 0 5px;
      border-radius: 5px 0 0 5px;
      background: #f3ecdc;
      display: flex;
      justify-content: space-around;
      font-size: 18px;
      color: #5A4944;
      .el-icon{
        font-weight: bold;
        height: 36px;
        line-height: 40px;
      }
      .citys{
        display: inline-block;
        white-space:nowrap;
      }
    }
    .right{
      flex: 1;
      min-width: 170px;
      padding: 0 10px;
      border-radius:0 5px 5px 0;
      display: flex;
      font-size: 18px;
      color: #FFFFFF;
      justify-content: space-around;
      background: #5a4944;
      p{
        .wuliuprice{
          color: #F3ECDC;
          font-size: 20px;
        }
      }
    }
  }
}
</style>
