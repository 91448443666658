<template>
  <div class="live">
    <div id="title">
      <Title :msg='{title: $t("m.index.Live_broadcast_hall"), to:"/live" }'/>
    </div>

    <div class="liveshow">
      <ul>
        <li v-for="i in leftLiveList" :key="i" @click="toLive(i)">
          <img :src="i.coverUrl" alt="">
        </li>
        <p class="cont_p" v-if="leftLiveList.length == 0">... {{$t("m.index.no_live_information_available")}} ...</p>
      </ul>
    </div>
  </div>
</template>
<script>
import { ref } from 'vue-demi'
import Title from '../../components/Title'
import http from '../../api/http'

// 查询直播大厅内容
const listLiveRooms = (data) => {
  return http.get('/mall-portal/aliyunLive/listLiveRooms', data)
}
export default {
  name: 'Live',
  data () {
    return {
      loading: ref(true),
      leftLiveList: ref([]) // 大厅直播左侧数据
    }
  },
  methods: {
    async initData () {
      this.loading = true
      const data = {
        status: '1', // 0已创建未开播 1直播中 2直播结束
        pageNumber: 1,
        pageSize: 4
      }
      const res = await listLiveRooms(data)
      if (res.status === 200) {
        this.leftLiveList = res.data.body.result.liveList
        this.loading = false
      }
    },
    async toLive (item) {
      console.log(item.anchorId)
      if (item.anchorId !== undefined) {
        this.$router.push({
          path: '/live/detail',
          query: {
            anchorId: item.anchorId,
            bizId: item.liveId
          }
        })
      }
    }
  },
  mounted () {
    this.initData()
  },
  components: {
    Title
  }
}
</script>
<style lang="scss" scoped>
.live{
  // padding: 0 20px;
  background: #FFFFFF;
  .liveshow{
    padding: 20px 0;
    ul{
      display: flex;
      li{
        width: 275px;
        height: 155px;
        // flex: 1;
        float: left;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .cont_p{
        flex: 1;
        width: 100%;
        text-align: center;
        color: #c88756;
        font-size: 30px;
        margin: 30px 0;
      }
    }
  }
}

</style>
