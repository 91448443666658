<template>
  <div id="content">
    <div class="left_cont">
      <div class="choose">
        <ul class="choose_btn_par">
          <li class="choose_btn_child" v-for="(item,index) in btnlist" :key="index">
            <button class="child_btn"
            :class="isaclass(item)?'acrbtn':''"
            @click="addclass(item)"
            >{{item}}</button>
          </li>
        </ul>
      </div>
      <div id="figure">
        <k-line v-if="dataStatus" :id="dataId"></k-line>
      </div>
    </div>
    <div class="right_cont">
      <Biao/>
    </div>
  </div>
</template>
<script>
import { ref } from 'vue-demi'
// import * as echarts from 'echarts'
import Biao from '../../components/Biao'
import KLine from '../../components/kline/KLine.vue'

export default {
  data () {
    return {
      btnlist: ref([
        this.$t('m.index.Port_of_Kunshan'),
        this.$t('m.index.New_York_Futures'),
        // this.$t('m.index.Jumbo_Futures'),
        // this.$t('m.index.Singapore_Commodities'),
        this.$t('m.index.London_futures')
        // 'Three'
      ]),
      isactbtn: ref([this.$t('m.index.Singapore_Commodities'), this.$t('m.index.Port_of_Kunshan')]),
      dataId: '',
      dataStatus: true
    }
  },
  components: {
    Biao,
    KLine
  },
  mounted () {
    this.getOptions()
  },
  methods: {
    isaclass (item) {
      for (const i of this.isactbtn) {
        if (i === item) {
          return true
        }
      }
    },
    addclass (item) {
      // this.dataStatus = false
      // this.dataStatus = true
      // console.log(this.dataStatus)
      for (const i of this.isactbtn) {
        if (i === item) {
          this.isactbtn.splice(this.isactbtn.indexOf(i), 1)
          return
        }
      }
      this.isactbtn.push(item)
      this.isactbtn = Array.from(new Set(this.isactbtn))
      console.log(this.isactbtn)
    },
    getOptions () {
      this.dataId = 1
    }
  },
  setup () {
    return {
      isactive: false
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../style/viriables.scss';
#content{
  display: flex;
  // background: red;
  padding: 20px 0;
  .left_cont{
    display: flex;
    flex-direction: column;
    width: 410px;
    padding: 10px;
    padding-right: 0;
    .choose{
      .choose_btn_par{
        display: flex;
        flex-wrap: wrap;
        .choose_btn_child{
          margin: 2.5px auto;
          // flex-basis: 33.33%;
          .child_btn{
            border: none;
            font-weight: bold;
            border-bottom:1px solid #ffffff;
            display: block;
            margin: 0 auto;
            width: 100px;
            // height: 26px;
            color: #D9D9D9;
            min-height: 30px;
            background: #ffffff;
            // border-radius: 4px 0px 0px 4px;
          }
          .acrbtn{
            color: #5A4944;
            border-bottom:1px solid #5A4944;
            // background: $bgColor;
          }
        }
      }
    }
    #figure{
      height: 300px;
      width: 410px;
      // background: green;
      margin-top: 20px;
    }
  }
  .right_cont{
    box-sizing: border-box;
  }
}
</style>
