<template>
<div class='swiper_main'>
  <div class="swiper_left"></div>
  <div class="swiper_body">
    <ul class="swiper_body_ul" v-for="i in swiperData" :key="i">
      <li  class="swiper_body_li">{{i.name}}</li>
      <!-- <li  class="swiper_body_li"></li>
      <li  class="swiper_body_li"></li> -->
    </ul>
  </div>
  <div class="swiper_right"></div>
</div>
</template>

<script>
export default {
  name: 'swiperMain',
  props: {
    swiperData: null
  },
  data () {
    return {
      img: []
    }
  },
  components: {},
  methods: {

  },
  mounted () {
    console.log(this.$props.swiperData)
    // this.img = this.$props.swiperData
  },
  setup (props, ctx) {
    return {

    }
  }
}
</script>
<style lang='scss' scoped>
//@import ''; 引入公共css类

</style>
