<template>
  <div class="content" v-loading="loading">
    <div class="nav_title">
      <span class="zi1">{{$t('m.index.Estate_presale')}}</span>
      <i class="zi2" @click="tonextPage">{{$t('m.index.Next_estate')}}&gt;</i>
    </div>
    <div id="video">
    </div>

    <div class="tent" v-if="yushouList.length !== 0">
      <p class="t1">{{$t('m.index.Pre_sale_name')}}：{{yushouList.name}}</p>
      <p class="t2">{{$t('m.index.price')}}：<span><strong>{{yushouList.currencySymbol}}{{yushouList.price}}</strong>/{{yushouList.unit}}</span></p>
      <p class="t3">{{$t('m.index.capacity')}}：{{yushouList.stock}}{{yushouList.unit}}</p>
      <p class="t4">{{$t('m.index.Founders_Day')}}：{{yushouList.createTime}}</p>
    </div>
    <div class="tent" v-else>
      <p class="t1">{{$t('m.index.Pre_sale_name')}}：......</p>
      <p class="t2">{{$t('m.index.price')}}：<span><strong>......</strong></span></p>
      <p class="t3">{{$t('m.index.capacity')}}：......</p>
      <p class="t4">{{$t('m.index.Founders_Day')}}：......</p>
    </div>
    <div>
      <el-button size="small" @click="toDetails(yushouList)" :disabled="disabled">{{$t('m.index.reserve')}}</el-button>
    </div>
  </div>
</template>
<script>
import { reactive, ref } from '@vue/runtime-core'
import Player from 'xgplayer'
import http from '../../../api/http'

export default {
  name: 'Video',
  data () {
    return {
      yushouList: reactive([]),
      num: ref(1),
      pageNum: ref(''),
      loading: ref(true),
      disabled: ref(false),
      playerV: null
    }
  },
  mounted () {
    http.get('/mall-portal/product/search', {
      pageSize: 1,
      pageNum: this.num,
      manorPreSale: 0,
      previewStatus: 1
    }).then(res => {
      this.pageNum = res.data.data.total
      this.yushouList = res.data.data.list[0]
      if (this.pageNum === 0) {
        this.yushouList = {
          videoAddress: 'http://s2.pstatp.com/cdn/expire-1-M/byted-player-videos/1.0.0/xgplayer-demo.mp4',
          pic: 'http://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/admin/ys/20220330/1646636467(1).jpg'
        }
        this.disabled = true
      }
      // console.log(this.yushouList)
      this.playerV = new Player({
        id: 'video',
        autoplay: false,
        width: 555,
        height: 311,
        url: this.yushouList.videoAddress || 'http://s2.pstatp.com/cdn/expire-1-M/byted-player-videos/1.0.0/xgplayer-demo.mp4',
        poster: this.yushouList.pic || 'http://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/admin/ys/20220330/1646636467(1).jpg'
        // playbackRate: [0.5, 0.75, 1, 1.5, 2] // 传入倍速可选数组
      })
      this.loading = false
    }).catch(err => {
      console.log(err)
    })
    // this.initData(1)
  },
  methods: {
    toDetails (data) {
      console.log(data)
      // this.$emit('dataToDetail', data)
      this.$router.push({
        path: '/reservation/varietiy_details',
        query: {
          productSn: data.productSn
        }
      })
    },
    initData (num) { // 初始化庄园预售
      this.loading = true
      // let play = null
      http.get('/mall-portal/product/search', {
        pageSize: 1,
        pageNum: num,
        manorPreSale: 0,
        previewStatus: 1
      })
        .then(res => {
          // console.log(res)
          if (res.data.code === 200) {
            if (res.data.data.list.length === 0) {
            // console.log('没有庄园预售数据')
              this.disabled = true
              this.loading = false
              return ''
            }
            this.yushouList = res.data.data.list[0]

            this.playerV.src = this.yushouList.videoAddress
            this.playerV.poster = this.yushouList.pic
            this.loading = false
          }
        // console.log(this.yushouList)
        // emitter.emit('preSaleList', res.data.data)
        }).catch(err => {
          console.log(err)
        })
    },
    tonextPage () {
      this.num++
      if (this.num > this.pageNum) {
        this.num = 1
      }
      this.initData(this.num)
    }
  },
  setup () {
    // onMounted(() => {
    //   const player = new Player({
    //     id: 'video',
    //     autoplay: false,
    //     width: 555,
    //     height: 311,
    //     url: 'http://s2.pstatp.com/cdn/expire-1-M/byted-player-videos/1.0.0/xgplayer-demo.mp4',
    //     poster: 'https://s2.pstatp.com/cdn/expire-1-M/byted-player-videos/1.0.0/poster.jpg'
    //     // playbackRate: [0.5, 0.75, 1, 1.5, 2] // 传入倍速可选数组
    //   })
    //   return player
    // })
  }
}
</script>
<style lang="scss" scoped>
.content{
  .nav_title{
    display: flex;
    justify-content: space-between;
    .zi1{
      float: left;
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #333333;
      line-height: 39px;
    }
    .zi2{
      text-align: right;
      line-height: 39px;
      float: right;
      color: #c3c3c3;
      cursor: pointer;
    }
  }
  #video{
    width: 555px;
    height: 311px;
    margin: 20px 0;
    // background: darkcyan;
  }
  .tent{
    margin:20px 0;
    p{
      line-height: 39px;
    }
    .t1{
      font-size: 22px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #333333;
    }
    .t2,.t3,.t4{
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
    }
    .t2{
      span{
        color: #ca8c5c;
        strong{
          font-size: 20px;
        }
      }
    }
  }
}
</style>
