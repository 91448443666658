<template>
  <div class="yushou">
    <div id="title">
      <Title :msg='{title: $t("m.index.Presale_hall"), to:"/reservation" }'/>
    </div>

    <div class="yushoushow">
      <ul>
        <li v-for="(item,index) in yushouList" :key="index">
          <div class="yscont"
          @click="toDetails(item)">
            <img :src="item.pic" alt="">
            <p class="p1 p2">
              {{item.name}}
              <span>
                {{item.currencySymbol+item.price}}
                <i class="zititi">/{{item.unit}}</i>
              </span>
            </p>
            <p class="zititi">{{$t("m.index.sales_volume")}}：<i class="p2">{{item.sale}}</i></p>
            <p class="zititi">{{$t("m.index.expiration_date")}}：<i class="p2">{{item.offerExpiryDate}}</i></p>
          </div>
        </li>
        <p class="cont_p" v-if="yushouList.length == 0">... {{$t("m.index.no_presale_information_available")}} ...</p>
      </ul>
    </div>
  </div>
</template>
<script>
import http from '../../api/http'
import Title from '../../components/Title'

export default {
  name: 'Yushou',
  data () {
    return {
      yushouList: []
    }
  },
  props: {
    // msgs: String
  },
  components: {
    Title
  },
  methods: {
    toDetails (data) {
      console.log(data)
      // this.$emit('dataToDetail', data)
      this.$router.push({
        path: '/reservation/varietiy_details',
        query: {
          productSn: data.productSn
        }
      })
    }
  },
  mounted () {
    http.get('/mall-portal/product/search', {
      pageSize: 4,
      pageNum: 1,
      previewStatus: 1
    })
      .then(res => {
        // console.log(res)
        if (res.data.code === 200) {
          this.yushouList = res.data.data.list
        }
        // emitter.emit('preSaleList', res.data.data)
      }).catch(err => {
        console.log(err)
      })
  },
  setup () {
    return {
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../style/viriables.scss';
.yushou{
  background: #FFFFFF;
  .yushoushow{
    padding-bottom: 20px;
    ul{
      width: 1200px;
      box-sizing: border-box;
      display: flex;
      // justify-content: space-between;
      // justify-content: space-evenly;

      align-items: center;
      flex-wrap: wrap;
      li{
        // width: 24%;
        max-width: 260px;
        padding: 19px 19px;
        flex: 1;
        display: block;
        border-right: 1px solid $bgColor;
        border-bottom: 1px solid $bgColor;
        border-top: 0;
        .yscont{
          // margin-bottom: 20px;
          cursor: pointer;
          p{
            line-height: 35px;
          }
          img{
            width: 260px;
            height: 260px;
          }
          .p1{
            color: #333333;
            width: 260px;
            span{
              display: block;
              float: right;
              text-align: right;
              color: #c88756;
              font-size: 20px;
            }
          }
          .zititi{
            color: #999999;
            font-size: 14px;
            .p2{
              color: #333333;
            }
          }
        }
      }
      .cont_p{
        flex: 1;
        width: 100%;
        text-align: center;
        color: #c88756;
        font-size: 30px;
        margin: 30px 0;
      }
    }
  }
}

</style>
