<template>
<div class="cont">
    <!-- 隐藏 -->
  <!-- <div class="leftv">
    <Video/>
  </div> -->
  <div class="right">
    <div class="z">
      <Zixun/>
    </div>
    <div class="b">
      <Bang/>
    </div>
  </div>
</div>
</template>
<script>
import Video from './videozibang/Video'
import Zixun from './videozibang/Zixun'
import Bang from './videozibang/Bang'

export default {
  name: 'VZB',
  components: {
    Video, Bang, Zixun
  }
}
</script>
<style lang="scss" scoped>
@import '../../style/viriables.scss';
.cont{
  display: flex;
  background: $bgColor;
  justify-content: space-between;
  .leftv{
    display: flex;
    // height: 650px;
    width: 595px;
    padding: 20px;
    box-sizing: border-box;
    background: #ffffff;
  }
  .right{
    // float: right;
    // margin-left: 10px;
    // height: 100%;
    // flex: 1;
    background: #f2f2f2;
    display: flex;
    flex-wrap: wrap;
    // flex-direction:column;
    .z{
      width: 600px;
      min-height: 335px;
      flex: 1;
      margin-bottom: 10px;
      background: #ffffff;
    }
    .b{
      width: 600px;
      height: 340px;
      box-sizing: border-box;

      // background: #ffffff;
      // background: cadetblue;
      overflow: hidden;
    }
  }
}
</style>
