<template>
<div class="nav">
  <div class="header-nav">
    <ul>
      <li v-for="(item,index) in headernav" :key="index">
        <!-- 暂时空着  图标待定 -->
        <span class="na-ico">
          <el-icon>
            <component :is="item.icon"></component>
          </el-icon>
        </span>
        <!-- <img style="width: 200px;" :src="imgurl">-->
              <!-- <i class="na-text">{{item.text}}</i> -->
         <el-popover
            placement="bottom"
            :width="200"
            trigger="hover"
          >
            <template #reference>
              <i class="na-text">{{item.text}}</i>
            </template>
            <template #default>
              <img style="width: 200px;" :src="item.url" alt="">
            </template>
          </el-popover>
      </li>
      <li class="nav_li_right"><a @click="toSell">{{$t('m.Header.Switch_merchant_side')}}</a></li>
      <li class="nav_li_right"><a @click="this.$router.push('/coffee')">{{$t('m.Header.coffe_community')}}</a></li>
      <li class="nav_li_right">
        <el-select v-model="optionsValue" size="mini" @change="choseOpt">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </li>
    </ul>
  </div>
</div>
</template>
<script>
import { ElMessage } from 'element-plus'
import { getStore, removeStore, setStore } from '../../tools/storage'
// import { ref, unref } from 'vue-demi'
// import zh from '../../lang/zh.js'
// import en from '../../lang/en.js'

// console.log(zh)
// console.log(en)

export default {
  name: 'Header',
  data () {
    return {
      headernav: [
        { icon: 'Iphone', text: this.$t('m.Header.APP_Download'), url: 'https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/weixin/app.png' },
        { icon: 'ChatRound', text: this.$t('m.Header.Official_WeChat'), url: 'https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/weixin/%E5%AE%98%E6%96%B9%E5%BE%AE%E4%BF%A1%E5%85%AC%E4%BC%97%E5%8F%B7.jpg' },
        { icon: 'UserFilled', text: this.$t('m.Header.customer_service'), url: 'https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/weixin/Snipaste_2023-08-24_16-39-24.png' }
      ],
      imgurl: 'https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/weixin/%E5%AE%98%E6%96%B9%E5%BE%AE%E4%BF%A1%E5%85%AC%E4%BC%97%E5%8F%B7.jpg',
      content: `
      <div>
        <img style="width: 200px;" :src="&{imgurl}">
      </div>
      `,
      options: [{
        value: 'zh-CN',
        label: '中文'
      }, {
        value: 'en-US',
        label: 'English'
      }],
      optionsValue: 'zh-CN'
    }
  },
  mounted () {
    // console.log(getStore('exp2c_language') === null ? getStore('exp2c_language') : 'zh-CN')
    this.optionsValue = this.$i18n.locale
    setStore('exp2c_language', this.optionsValue)
  },
  methods: {
    toSell () {
      // console.log(getStore('usermessage'))
      let item = getStore('usermessage')

      if (item) {
        try {
          item = JSON.parse(item)
        } catch (error) {
          // eslint-disable-next-line no-self-assign
          item = null
        }
        if (item) {
          console.log(item)
          if (item.type === 0) {
            ElMessage.error('没有相关权限')
            return ''
          } else {
            this.$router.push('/merchantworkbench')
            setTimeout(() => {
              window.location.reload() // 页面重新加载
            }, 150)
          }
        } else {
          removeStore('usermessage')
          ElMessage.warning({
            message: '没有检测到登录信息或登录已过期，请登录后操作',
            duration: 2000
          })
          // return
        }
      }
    },
    choseOpt (e) {
      console.log(e)
      setStore('exp2c_language', e)
      // console.log(this.$i18n.locale);
      this.$i18n.locale = e
      setTimeout(() => {
        window.location.reload() // 页面重新加载
      }, 150)
    }
  },
  setup () {
    return {

    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../style/index.scss';
.nav{
  height: 30px;
  width: 1200px;
  margin: 0 auto;
  // background: red;
  ul{
    display: block;
    line-height: 30px;
    li{
      float: left;
      display: flex;
      // width: 56px;
      height: 30px;
      margin-right: 20px;
      cursor: pointer;
      .na-text{
        font-size: 14px;
        font-weight: 400;
        color: $textColor;
        line-height: 30px;
      }
      .na-ico{
        display: inline-block;
        box-sizing: border-box;
        width: 20px;
        height: 30px;
        // border: 1px solid black;
        line-height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        .el-icon{
          color: #666666;
          font-size: 18px;
        }
      }
    }
    .nav_li_right{
      float: right !important;
      a{
        color: #666;
      }
    }
  }
  .header-nav{
    .el-select{
      width: 100px;
    }
  }
}
.el-select-dropdown__item{
  color: $textColor;
}
</style>
