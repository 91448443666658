<template>
  <div class="yushou">
    <div id="title">
      <Title :msg='{title: $t("m.index.Barter_hall"), to:"/barter" }'/>
    </div>

    <div class="yushoushow">
      <ul>
        <li v-for="(item,index) in yihuoList" :key="index">
          <div class="yscont"
          @click="toDetails(item)">
            <!-- <span class="yushouCont">会员专享</span> -->

            <img :src="item.pic" alt="">
            <p class="p1 p2">
              {{$t("m.index.existing")}}：
              {{item.name}}
              <span>
                {{item.stock}}<i class="zititi">{{item.unit}}</i>
              </span>
            </p>
            <p class="p2">
              {{$t("m.index.target")}}：
              <i>{{item.pmsExchangeSkuStockEntity.demandProductName}}</i>
              <span>
                {{item.pmsExchangeSkuStockEntity.stock}}<i class="zititi">{{item.unit}}</i>
              </span>
            </p>
          </div>
        </li>
        <p class="cont_p" v-if="yihuoList.length == 0">... {{$t("m.index.no_barter_information_available")}} ...</p>
      </ul>
    </div>
  </div>
</template>
<script>
import { ElMessage } from 'element-plus'
import http from '../../api/http'
import Title from '../../components/Title'
import { getStore } from '../../tools/storage'

// 查询易货大厅数据
const getExchangeProduct = (data) => {
  return http.get('/mall-portal/portal/pmsexchangeproduct/getExchangeProduct', data)
}
export default {
  name: 'Yihuo',
  props: {
    msgs: String
  },
  data () {
    return {
      yihuoList: []
    }
  },
  components: {
    Title
  },
  methods: {
    async initdata () {
      const data = {
        pageNum: 1,
        pageSize: 4
      }
      try {
        const res = await getExchangeProduct(data)
        // console.log(res)
        if (res.data.code === 200) {
          this.yihuoList = res.data.data.list
          // // this.totalnum = res.data.data.total
          // // console.log(this.yihuoList)
          // console.log(res.data)
        }
      } catch (error) {
        console.log(error)
      }
    },
    toDetails (data) {
      console.log(data)
      if (!JSON.parse(getStore('usermessage'))) {
        ElMessage.warning({
          message: '没有找到登录信息！！'
        })
        return
      }
      // const barterMember = JSON.parse(getStore('usermessage')).barterMember
      // if (barterMember) {
      //   this.$router.push({
      //     path: '/barter/detail',
      //     query: {
      //       id: data.id
      //     }
      //   })
      // } else {
      //   ElMessage.warning({
      //     message: '您没有权限查看，请先成为易货会员！'
      //   })
      //   return ''
      // }
      this.$router.push({
        path: '/barter/detail',
        query: {
          id: data.id
        }
      })
    }
  },
  mounted () {
    this.initdata()
  },
  setup () {
    return {
      // yihuoList
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../style/viriables.scss';
.yushou{
  // padding: 10px 20px;
  background: #FFFFFF;
  .yushoushow{
    padding-bottom: 20px;
    ul{
      width: 1200px;
      box-sizing: border-box;
      display: flex;
      // justify-content: center;
      // justify-content: space-evenly;
      align-items: center;
      flex-wrap: wrap;
      li{
        // flex: 1;
        // width: 25%;
        padding: 19px 19px;
        // float: left;
        display: block;
        border-right: 1px solid $bgColor;
        border-bottom: 1px solid $bgColor;
        border-top: 0;
        .yscont{
          // margin-bottom: 20px;
          display: flex;
          flex-flow: column;
          cursor: pointer;
          .yushouCont{
            width: 60px;
            height: 20px;
            font-size: 12px;
            color: #ffffff;
            text-align: center;
            line-height: 20px;
            background: linear-gradient(0deg, #ac3d3b 0%, #FFBC88 100%);
            border-radius: 0px 0px 4px 0px;
            position: absolute;
          }
          p{
            line-height: 35px;
          }
          img{
            width: 260px;
            height: 260px;
          }
          .p1,.p2{
            color: #333333;
            width: 260px;
            display: inline-block;
            span{
              display: block;
              float: right;
              color: #c88756;
              font-size: 20px;
            }
            i{
              color: #c88756;
            }
          }
          .zititi{
            color: #999999 !important;
            font-size: 14px;
            .p2{
              color: #333333;
            }
          }
        }
      }
      .cont_p{
        flex: 1;
        width: 100%;
        text-align: center;
        color: #c88756;
        font-size: 30px;
        margin: 30px 0;
      }
    }
  }
}

</style>
