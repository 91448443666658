<template>
  <div id="bigimg">
    <img src="../../static/imges/bigbanner01.png" alt="Coffer">
    <!-- <div class="title">
      <a :href="data.url.href">
        <p class="tit1">{{data.text.tit1}}</p>
        <p class="tit2">{{data.text.tit2}}</p>
      </a>
    </div> -->
  </div>
</template>
<script>
const data = {
  url: {
    img: '',
    href: ''
  }
}

export default {
  name: 'Bigimg',
  setup () {
    return {
      data
    }
  }
}
</script>
<style lang="scss" scoped>
#bigimg{
  // 图片
  // height: 116.95px;
  padding: 0;
  margin: 0;
  img{
    // width: 1200px;
    width: 100%;
    // height: 200px;
    // margin: 0 auto;
  }

  // 样式
  // .title{
  //   // position: relative;
  //   right: 808px;
  //   top: 30px;
  //   a{
  //     .tit1{
  //       // width: 309px;
  //       height: 39px;
  //       font-size: 39px;
  //       font-family: Source Han Serif SC;
  //       font-weight: 800;
  //       color: #FFFFFF;
  //       line-height: 39px;
  //     }
  //     .tit2{
  //       text-indent: 30px;
  //       // width: 303px;
  //       height: 16px;
  //       font-size: 16px;
  //       font-family: Source Han Sans SC;
  //       font-weight: 400;
  //       color: #FFFFFF;
  //       line-height: 39px;
  //     }
  //   }
  // }
}
</style>
