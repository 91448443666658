<template>
  <div class="detail">
    <div class="leftzi">
      <div class="top">
        <div class="yscont">
            <div class="toauction">
              <span>
                {{$t("m.index.Enter_auction_hall")}}
              </span>
              <span class="to_span" @click="this.$router.push({name:'Auction', params:{pattern:item}})">
                {{$t("m.index.more")}}
                <el-icon size="22"><CaretRight /></el-icon>
              </span>
            </div>

            <p class="p1">
              {{$t("m.index.trade_name")}}：{{yuhsouList.productName}}
              <span>{{$t("m.index.Latest_price")}}：<i class="zititi"><NologindataVue :price='smsAuctionSku.realPrice' /></i>/{{yuhsouList.unit}}</span>
            </p>
            <p class="p2">{{$t("m.index.name")}}：
              <i class="c1 p2">{{yuhsouList.productName}}</i>
              <span>{{$t("m.index.starting_price")}}：
                <i class="zititi">{{smsAuctionSku.minqp}}</i>
                {{$t("m.index.yuan")}}
                </span>
            </p>
            <p class="p2">{{$t("m.index.Delivery_Date")}}：
              <i class="c1 p2">{{time}}</i>
              <span>{{$t("m.index.Minimum_markup")}}：
                <i class="zititi">{{smsAuctionSku.minqj}}</i>
              {{$t("m.index.yuan")}}
              </span>
            </p>
        </div>
        <div class="price">
          <div class="showprice"><NologindataVue :price='smsAuctionSku.realPrice' /></div>
          <div class="btnprice">
            <el-button :disabled="smsAuctionSku==''" @click="topage(this.yuhsouList)">{{$t("m.index.bidding")}}</el-button>
          </div>
        </div>
      </div>
      <div class="bottomzi">
        <p class="cont">
          <span class="titlec">{{$t("m.reservation_varietiy_details.Supplier_file")}}：</span>
           {{supplierProfile}}</p>
      </div>
    </div>
    <div class="righttu">
      <div class="righttop">
        <button
        v-for="(item,index) in btnlist" :key="index"
        :class="{active:activeClass == index}"
        @click="btnclass(index)">{{item}}</button>
      </div>
      <div class="rightbottom">
        <img v-if="activeClass==0&&yingList.length==0||activeClass==1&&helanList.length==0" src="../../static/imges/jingpai.jpg" alt="">
        <div class="bottomlist" v-show="activeClass==0&&yingList.length!=0">
          <ul>
            <li v-for="(item,index) in yingList" :key="index">
              <div class="yscont"
                @click="toDetails(item)">
                  <div class="card_img">
                    <img :src="item.productPic">
                    <span class="overtime" v-if="item.status==0">
                      {{$t("m.index.start_time")}}：<i>{{item.startTime}}</i>
                    </span>
                    <span class="overtime" v-if="item.status==1">
                      {{$t("m.auction.end_time")}}：<i>{{item.endTime}}</i>
                    </span>
                  </div>
                  <p class="p1 ptitle">
                    {{item.name}}
                  </p>
                  <p class="p1 ptitle">
                    {{item.productName}}
                  </p>
                  <p class="p1">
                    <span>
                        <i class="zititi c1">￥</i>
                        <NologindataVue :price='item.smsAuctionSku.realPrice' />
                        <i class="zititi">/{{item.unit}}</i>
                    </span>
                  </p>
                </div>
            </li>
          </ul>
        </div>
        <div class="bottomlist" v-show="false">
          <ul>
            <li v-for="(item,index) in helanList" :key="index">
              <div class="yscont"
                @click="toDetails(item)">
                  <div class="card_img">
                    <img :src="item.productPic">
                    <span class="overtime" v-if="item.status==0">
                      {{$t("m.index.start_time")}}：<i>{{item.startTime}}</i>
                    </span>
                    <span class="overtime" v-if="item.status==1">
                      {{$t("m.auction.end_time")}}：<i>{{item.endTime}}</i>
                    </span>
                  </div>
                  <p class="p1 ptitle">
                    {{item.name}}
                  </p>
                  <p class="p1 ptitle">
                    {{item.productName}}
                  </p>
                  <p class="p1">
                    <span>
                        <i class="zititi c1">￥</i>
                        <NologindataVue :price='item.smsAuctionSku.realPrice' />
                        <i class="zititi">/{{item.unit}}</i>
                    </span>
                  </p>
                </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from 'vue-demi'
import http from '../../api/http'
import NologindataVue from '../../components/Nologindata.vue'

const AuctionData = (data) => {
  return http.get('/mall-buyer/auction/getAuctionList', data, true)
}
export default {
  data () {
    return {
      btnlist: [
        this.$t('m.auction.English_Auction')
      ],
      activeClass: 0,
      yuhsouList: ref(''),
      smsAuctionSku: ref(''),
      time: ref(''),

      helanList: [],
      yingList: [],
      supplierProfile: ''
    }
  },
  components: {
    NologindataVue
  },
  methods: {
    btnclass (k) {
      this.activeClass = k
    },
    async initData (num, status) { // 查询数据 默认显示英式(向上)
      const data = {
        type: status === 0 ? 0 : status === 1 ? 1 : null,
        section: '',
        pageSize: num, // 页面个数
        pageNum: 1, // 第几页
        status: null,
        // specialSubjectCityCode:this.cityCode,
        specialSubjectId: ''
      }
      const res = await AuctionData(data)
      if (res.data.code === 200) {
        // console.log(res)
        if (res.data.data.total === 0) {
          // console.log('没有竞拍数据')
          return
        }
        if (num === 1) {
          this.yuhsouList = res.data.data.records[0]
          this.smsAuctionSku = this.yuhsouList.smsAuctionSku
          this.yuhsouList.smsAuctionSku.spData = JSON.parse(this.yuhsouList.smsAuctionSku.spData)
          this.time = this.smsAuctionSku.spData[0].value
          this.supplierProfile = this.yuhsouList.umsCompanyInfoEntity.note
          // console.log(this.yuhsouList)
        } else {
          return res.data.data
        }
      }
    },
    topage (data) {
      this.$router.push({
        path: '/auction/englishauction', // 英式 暂时都是相同页面
        query: {
          id: data.id,
          type: data.type
        }
      })
    },
    toDetails (data) {
      this.$router.push({
        path: '/auction/englishauction', // 荷兰式
        query: {
          id: data.id,
          type: data.type
        }
      })
    }
  },
  mounted () {
    this.initData(1)
    const ying = this.initData(4, 0)
    const helan = this.initData(4, 1)
    ying.then(res => {
      // console.log(res)
      if (res === undefined) {
        return
      }
      this.yingList = res.records
    }).catch(err => {
      console.log(err)
    })
    helan.then(res => {
      // console.log(res)
      if (res === undefined) {
        return
      }
      // this.helanList = res.records
      this.helanList = []
    }).catch(err => {
      console.log(err)
    })
  },
  setup () {
    return {

    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../style/viriables.scss';

.detail{
  display: flex;
  background: #FFFFFF;
  .leftzi{
    width: 437px;
    padding: 30px;
    border-right: solid 1px $bgColor;
    .top{
      .yscont{
        .toauction{
          width: 100%;
          border-bottom: solid 1px $bgColor;
          display: flex;
          justify-content: flex-end;
          justify-content: space-between;
          align-items: center;
          span{
            color: #5b381e;
            font-size: 22px;
            font-weight: 600;
            line-height: 40px;
          }
          .to_span{
            font-size: 20px;
            font-weight: 400;
            color: #BEBEBE;
            display: flex;
            align-items: center;
            cursor: pointer;
          }
        }
        p{
          line-height: 35px;
          .zititi{
            font-size: 26px;
            color: #C6824E;
          }
          span{
            display: inline-flex;
            float: right;
          }
        }
        .p1{
          margin-bottom: 15px;
          font-size: 22px;
          font-weight: bold;
          color: #5A4944;
          height: 90px;
          line-height: 90px;
          border-bottom: solid 1px $bgColor;

          white-space: nowrap;
          // overflow: hidden;
          span{
            font-size: 14px;
          }
        }
        .p2{
          color: #999999;
          font-size: 14px;
          .c1{
            color: #333333;
          }
        }
        .p2:last-child{
          padding-bottom: 15px;
          border-bottom: solid 1px $bgColor;
        }
      }
      .price{
        display: flex;
        margin: 30px 0;
        .showprice{
          flex: 1;
          float: right;
          text-align: right;
          font-size: 36px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #3B1C01;
          line-height: 60px;
          // height: 60px;
        }
        .btnprice{
          flex: 1;
          display: block;
          button{
            float: right;
            width: 160px;
            height: 60px;
            background: linear-gradient(0deg, #3B1C01 0%, #663102 100%);
            border-radius: 4px;
            font-size: 20px;
            font-weight: bold;
            color: #FFFFFF;
          }
        }
      }
    }
    .bottomzi{
      width: 437px;
      // height: 268px;
      .cont{
        font-size: 14px;
        // font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        line-height: 21px;
        .titlec{
          color: #333333;
        }
      }
    }
  }
  .righttu{
    padding: 30px;
    width: 600px;
    flex: 1;
    margin: 0 40px;
    // background: green;
    .righttop{
      margin-bottom: 20px;
      display: flex;
      button{
        flex: 1;
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #3B1C01;
        line-height: 60px;
        border-radius: 4px 0px 0px 4px;
        border: 1px solid #3B1C01;
        // transition: all 0.5s;
        // cursor: pointer;
      }
      .active{
        color: #FFFFFF;
        background: linear-gradient(0deg, #3B1C01 0%, #663102 100%);
      }
    }
    .rightbottom{
      width: 600px;
      .bottomlist{
        width: 600px;
        // height: 472px;
        ul{
          width: 100%;
          height: 472px;
          display: flex;
          flex-flow: wrap;
          // justify-content: center;
          li{
            height: 50%;
            width: 50%;
            .yscont{
              position: relative;
              cursor: pointer;
              p{
                line-height: 35px;
              }
              .card_img{
                position: relative;
                width: 100%;
                height: 100%;
                img{
                  width: 150px;
                  height: 150px;
                }
                .overtime{
                  position: absolute;
                  bottom: 0;
                  left: 20px;
                  width: 90%;
                  display: block;
                  height: 30px;
                  text-align: center;
                  line-height: 30px;
                  background: #280E01;
                  opacity: 0.9;
                  font-size: 14px;
                  font-weight: 400;
                  color: #C6824E;
                }
              }
              .p1{
                color: #333333;
                font-size: 14px;
                // margin: ;
                font-family: Microsoft YaHei;
                font-weight: 400;
                width: 260px;
                padding-left:20px ;
                span{
                  display: inline-block;
                  font-weight: bold;
                  text-align: right;
                  color: #c88756;
                  font-size: 20px;
                  .c1{
                    color: #c88756;
                    font-weight: 400;
                  }
                }
                .nextbtn{
                  float: right;
                  width: 100px;
                  height: 40px;
                  background: linear-gradient(0deg, #280E01 0%, #673000 100%);
                  border-radius: 4px;
                  color: #FFFFFF;
                }
              }
              .ptitle{
                line-height: 21px;
                margin: 5px 0;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
              }
              .zititi{
                color: #999999;
                font-size: 14px;
                .p2{
                  color: #333333;
                }
              }
            }
          }
        }
      }
      img{
        display: block;
        margin: 0 auto;
      }
    }
  }
}
</style>
