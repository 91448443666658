<template>
  <ul id="parent">
    <li class="child"
    v-for="(item, index) in data" :key="index"
    :class="{active: isActive === index}"
    @click="addclass(index, item.path)">
      <i class="icon">
        <img :src="item.img" alt="">
      </i>
      <span class="navtext">{{item.text}}</span>
    </li>
  </ul>
</template>

<script>

export default {
  name: 'Nav',
  data () {
    return {
      isActive: null,
      data: [
        // {
        //   img: 'https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/web/icon/%E9%A2%84%E5%94%AE.png',
        //   text: this.$t('m.search.Capacity_presale'), // 预售
        //   path: '/reservation'
        // },
        // {
        //   img: 'https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/web/icon/%E8%BD%AC%E5%94%AE.png',
        //   text: '转售',
        //   path: '/resell'
        // },
        {
          img: 'https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/web/icon/%E7%8E%B0%E8%B4%A7new.png',
          text: this.$t('m.search.Spot_listing'), // 现货
          path: '/cashcommodity'
        },
        // {
        //   img: 'https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/web/icon/%E6%98%93%E8%B4%A7.png',
        //   text: this.$t('m.search.barter'), // 易货
        //   path: '/barter'
        // },
        {
          img: 'https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/web/icon/%E4%B8%93%E5%8C%BAnew.png',
          text: this.$t('m.search.region'), // 专区
          path: '/region'
        },
        {
          img: 'https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/web/icon/%E6%8B%8D%E5%8D%96new.png',
          text: this.$t('m.search.bidding'), // 拍卖
          path: '/auction'
        }
        // {
        //   img: 'https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/web/icon/%E7%9B%B4%E6%92%AD.png',
        //   text: this.$t('m.search.live_streaming'), // 直播
        //   path: '/live'
        // }
      ]
    }
  },
  methods: {
    addclass (i, to) {
      // const loading = this.$loading({
      //   lock: true,
      //   text: '加载中'
      // })
      this.isActive = i
      setTimeout(() => {
        this.$router.push(to)
        // loading.close()
      }, 500)
    }
  },
  setup () {
    return {

    }
  }
}
</script>
<style lang="scss" scoped>
#parent{
  display: flex;
  // justify-content: center;
  .child{
    flex: 1;
    cursor: pointer;
    display: block;
    width: 145px;
    height: 128px;
    border-radius: 4px;
    // 空出图标位置
    border: 1px solid transparent;
    transition: all 0.2s;
    .icon{
      display: block;
      margin: 0 auto;
      margin-top: 10px;
      width: 60px;
      height: 60px;
      // border: 1px dashed #868686;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .navtext{
      display: block;
      text-align: center;
      font-size: 20px;
      font-weight: bold;
      // color: #FFFFFF;
      color: #F3ECDC;
      line-height: 39px;
    }
  }
  .active{
    background: rgba(247, 247, 247, 0.1);
    border-color: #C6824E;
  }
}
</style>
