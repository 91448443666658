<template>
<!-- 现货大厅 -->
  <div class="yushou">
    <div id="title">
      <Title :msg='{title: $t("m.index.spot_market"), to:"/cashcommodity" }'/>
    </div>

    <div class="yushoushow">
      <ul>
        <li v-for="(item,index) in yushouList" :key="index">
          <div class="yscont"
          @click="toDetails(item)">
            <img :src="item.pic" alt="">
            <p class="p1 p2">
              {{item.name}}
            </p>
            <p class="zititi">{{$t("m.index.sales_volume")}}：<i class="p2">{{item.sale}}</i>
              <span>
                <i class="unit_zititi">{{item.currencySymbol}}</i><NologindataVue :price='item.price' />
                <i class="unit_zititi">/{{item.unit}}</i>
              </span>
            </p>
            <p class="zititi">{{$t("m.index.expiration_date")}}：<i class="p2">{{item.offerExpiryDate}}</i></p>
          </div>
        </li>
        <p class="cont_p" v-if="yushouList.length == 0">... {{$t("m.index.no_data_spot_commodity")}} ...</p>
      </ul>
    </div>
  </div>
</template>
<script>
import http from '../../api/http'
import NologindataVue from '../../components/Nologindata.vue'
import Title from '../../components/Title'

export default {
  name: 'Xianhuo',
  data () {
    return {
      yushouList: []
    }
  },
  props: {
    // msgs: String
  },
  components: {
    Title, NologindataVue
  },
  methods: {
    toDetails (data) {
      console.log(data)
      // this.$emit('dataToDetail', data)
      const routeData = this.$router.resolve({
        path: '/cashcommodity/varietiy_details',
        query: {
          productSn: data.productSn
        }
      })
      window.open(routeData.href, '_blank')
    }
  },
  mounted () {
    http.get('/mall-portal/product/search', {
      pageSize: 4,
      pageNum: 1,
      previewStatus: 0
    })
      .then(res => {
        // console.log(res)
        if (res.data.code === 200) {
          this.yushouList = res.data.data.list
        }
        // emitter.emit('preSaleList', res.data.data)
      }).catch(err => {
        console.log(err)
      })
  },
  setup () {
    return {
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../style/viriables.scss';
.yushou{
  background: #FFFFFF;
  .yushoushow{
    padding-bottom: 20px;
    ul{
      width: 1200px;
      box-sizing: border-box;
      display: flex;
      // justify-content: space-between;
      // justify-content: space-evenly;

      align-items: center;
      flex-wrap: wrap;
      li{
        // width: 24%;
        // max-width: 260px;
        margin: 19px 19px;
        flex: 1;
        display: block;
        border-right: 1px solid $bgColor;
        border-bottom: 1px solid $bgColor;
        box-shadow: 0px 5px 11px 0px rgba(0,0,0,0.06);
        border-top: 0;
        .yscont{
          // margin-bottom: 20px;
          cursor: pointer;
          p{
            box-sizing: border-box;
            // line-height: 35px;
            padding: 10px;
          }
          img{
            width: 260px;
            height: 260px;
          }
          .p1{
            // display: inline;
            // color: #333333;
            font-weight: bold;
            color: #5A4944;
            font-size: 18px;
            width: 260px;
          }
          .unit_zititi{
            color: #999999;
            font-size: 14px;
          }
          .zititi{
            color: #999999;
            width: 100%;
            float: left;
            font-size: 14px;
            .p2{
              color: #333333;
            }
            span{
              display: block;
              float: right;
              text-align: right;
              font-weight: bold;
              color: #C4936C;
              font-size: 22px;
            }
          }
        }
      }
      .cont_p{
        flex: 1;
        width: 100%;
        text-align: center;
        color: #c88756;
        font-size: 30px;
        margin: 30px 0;
      }
    }
  }
}

</style>
