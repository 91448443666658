<template>
<div class=''>
  <div id="title">
    <Title :msg='{title: $t("m.index.logistics_service") }'/>
  </div>
  <div class="carousel_list">
    <el-tabs v-model="activeName" class="demo-tabs">
      <el-tab-pane label="省际专区" name="省际专区">
        <WuliuListVue :value="shengJiList" />
      </el-tab-pane>
      <el-tab-pane label="云南专区" name="云南专区">
        <WuliuListVue :value="yunNanList" />
      </el-tab-pane>
      <el-tab-pane label="港口专区" name="港口专区">
        <WuliuListVue :value="gangKouList" />
      </el-tab-pane>
    </el-tabs>
    <div class="yewu">业务咨询：zhouchen@cofeeccdt.com 周经理</div>
  </div>
</div>
</template>

<script>
import Title from '../../components/Title'
import WuliuListVue from './WuliuList.vue'
export default {
  name: 'WuliuSer',
  data () {
    return {
      activeName: '省际专区',
      shengJiList: [
        { start: '上海', end: '昆山综保区', currencySymbol: '￥', price: 85, unit: '吨' },
        { start: '苏州', end: '昆山综保区', currencySymbol: '￥', price: 80, unit: '吨' },
        { start: '合肥', end: '昆山综保区', currencySymbol: '￥', price: 160, unit: '吨' },
        { start: '杭州', end: '昆山综保区', currencySymbol: '￥', price: 120, unit: '吨' },
        { start: '无锡', end: '昆山综保区', currencySymbol: '￥', price: 95, unit: '吨' },
        { start: '宣城', end: '昆山综保区', currencySymbol: '￥', price: 140, unit: '吨' },
        { start: '滁州', end: '昆山综保区', currencySymbol: '￥', price: 160, unit: '吨' }
      ],
      yunNanList: [
        { start: '昆山综保区', end: '普洱', currencySymbol: '￥', price: 1400, unit: '吨' },
        { start: '昆山综保区', end: '临沧', currencySymbol: '￥', price: 1400, unit: '吨' },
        { start: '昆山综保区', end: '保山', currencySymbol: '￥', price: 1400, unit: '吨' },
        { start: '昆山综保区', end: '德宏州', currencySymbol: '￥', price: 1400, unit: '吨' }
      ],
      gangKouList: [
        { start: '外高桥', end: '昆山综保区', currencySymbol: '￥', price: 85, unit: '吨' },
        { start: '青岛黄岛港口', end: '昆山综保区', currencySymbol: '￥', price: 325, unit: '吨' },
        { start: '宁波港口', end: '昆山综保区', currencySymbol: '￥', price: 175, unit: '吨' },
        { start: '洋山港', end: '昆山综保区', currencySymbol: '￥', price: 90, unit: '吨' }
      ]
    }
  },
  components: { Title, WuliuListVue },
  methods: {

  },
  mounted () {

  },
  setup (props, ctx) {
    return {

    }
  }
}
</script>
<style lang='scss' scoped>
//@import ''; 引入公共css类
.carousel_list{
  padding: 20px 30px 30px 30px;
  position: relative;
  /deep/ .el-tabs{
    .el-tabs__header{
      .el-tabs__nav-wrap{
        .el-tabs__nav-scroll{
          display: flex;
          // justify-content: center;
          .el-tabs__nav{
            // margin: 0 auto;
            // height: 70px;
            .el-tabs__active-bar{
              background: #5A4944;
            }
            .el-tabs__item{
              width: 150px;
              text-align: center;
              font-size: 18px;
              font-weight: bold;
              color: #D9D9D9;
              padding: 0;
            }
            .is-active{
              color: #5A4944;
            }
            .el-tabs__item:hover{
              color: #6b5b57;
            }
          }
        }
      }
      .el-tabs__nav-wrap::after{
        width: 0;
      }
    }
  }
  .yewu{
    position: absolute;
    font-weight: 400;
    color: #BEBEBE;
    right: 30px;
    top: 20px;
  }
}
</style>
