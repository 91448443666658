<template>
  <div id="page">
    <div id="nav">
      <Header/>
    </div>
    <!-- <div id="bigimg" style="width:100%;">
      <BigImg/>
    </div> -->
    <div id="search">
      <Search/>
    </div>
    <div id="bigimg">
      <BigImg/>
    </div>
    <div id="tubiao">
      <TuBiao/>
    </div>
    <div id="vzb">
      <VZB/>
    </div>
    <div id="gy">
      <Gongying/>
    </div>
    <!-- 隐藏 -->
    <!-- <div id="live">
      <Live/>
    </div> -->
    <!-- 隐藏 -->
    <!-- <div id="yushou">
      <Yushou/>
    </div> -->
    <!-- 隐藏 -->
    <!-- <div id="zhuanshou">
      <Zhuanshou/>
    </div> -->
    <div id="xianhuo">
      <Xianhuo/>
    </div>
    <div id="detail">
      <Details/>
    </div>
    <div id="cangku">
      <CangkuVue/>
    </div>
    <div id="wuliu">
      <WuliuSerVue/>
    </div>
    <!-- 隐藏 -->
    <!-- <div id="yihuo">
      <Yihuo/>
    </div> -->
    <div id="footer">
      <Footer/>
    </div>
  </div>
</template>
<script>
import Header from './Header'
import BigImg from './BigImg'
import Search from './Search'
import TuBiao from './TuBiao'
import VZB from './VZB'
import Gongying from './Gongying'
import Live from './Live'
import Yushou from './Yushou'
// import Zhuanshou from './Zhuanshou'
import Xianhuo from './Xianhuo.vue'
import Details from './Details'
import CangkuVue from './Cangku.vue'
import WuliuSerVue from './WuliuSer.vue'
import Yihuo from './Yihuo'
import Footer from './Footer'
import { getStore } from '../../tools/storage'

export default {
  name: 'Index',
  props: {
    msg: String
  },
  components: {
    Header, Search, TuBiao, VZB, Gongying, Live, Yushou, Xianhuo, Details, CangkuVue, WuliuSerVue, Yihuo, Footer, BigImg
  },
  mounted () {
    // getStore('exp2c_language')
    console.log(getStore('exp2c_language'))
    if (getStore('exp2c_language') === 'en-US') {
      console.log('English')
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../style/viriables.scss';
  #page{
    background: $bgColor;
    width: 100%;
    margin: 0 auto;
    #nav{
      width: 100%;
      background: #ffffff;
      margin: 0 auto;
    }
    #tubiao,#vzb,#gy,#live,#yushou,#zhuanshou,#xianhuo,#detail,#cangku,#wuliu,#yihuo{
      width: 1200px;
      // margin: 10px 0;
      background: #ffffff;
      margin: 10px auto;
    }
    #bigimg{
      width: 1200px;
      // width: 100%;
      margin: 0 auto;
      // height: 120px;
      // background: #663914;
    }
    #search{
      // width: 1920px;
      width: 100%;
      background: #270e01;
    }
    #gy{
      // height: 224px;
    }
    // #live{
    //   height: 270px;
    // }
    #footer{
      background: #3B1C01;

    }
  }
</style>
